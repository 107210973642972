
@import "@scss/settings/functions.scss";

.landing-form__item {
    &-select {
      position: relative;
      width: 100%;
      &__label {
        position: relative;
        width: 100%;
        margin: 0;
        &:focus-within {
          & .landing-form__item-select__label-name {
            top: 0px;
            opacity: 1;
            font-size: 12px;
            transform: translateY(-50%);
            transition: top 0.3s, font-size 0.3s;
          }
        }
        &-name {
          position: absolute;
          overflow: hidden;
        max-width:calc(100% - 60px);
        white-space: nowrap;
        text-overflow: ellipsis;
          display: block;
          top: 50%;
          left: 16px;
          font-size: 16px;
          background-color: var(--clr-default-100);
          padding: 0 5px;
          transform: translateY(-50%);
          transition: top 0.3s, font-size 0.3s;
  
          // opacity: 0;
          &-active {
            opacity: 1;
            top: 0px;
            font-size: 12px;
            transform: translateY(-50%);
  
            transition: top 0.3s, font-size 0.3s;
          }
          &-disabled {
            background-color: transparent;
          }
          &-required {
            color: #f46767;
            margin-left: 5px;
          }
        }
      }
    }
    &-error {
      // margin-top: 6px;
      color: #f46767;
      font-size: 14px;
      @media (max-width: em(767)) {
        font-size: 12px;
      }
      
    }
  }
  .landing__form__select {
    &__error-message {
      color: #f46767;
      font-size: 14px;
      @media (max-width: em(767)) {
        font-size: 12px;
      }
    }
    &-error {
      & .landing__form__select__control {
        border-color: #f46767 !important;
      }
    }
  
    &__control {
      // padding: 16px;
      border-color: #e0e0e0 !important;
      min-height: 48px !important;
      max-height: 48px !important;
      font-size: 16px !important;
      & input {
        min-height: auto !important;
        padding: 0px !important;
      }
      &--menu-is-open {
        // border-color: #f46767 !important;
        box-shadow: none !important;
      }
  
      &--is-focused {
        border-color: #e0e0e0 !important;
      }
      &:hover,
      &:focus,
      &:focus-within {
        border-color: #e0e0e0 !important;
        box-shadow: none !important;
      }
    }
    &__value-container {
      padding: 7px 15px !important;
      color: transparent !important;
    }
    &__single-value {
      display: flex;
      align-items: center;
    }
    &__input-container {
      padding: 0 !important;
      margin: 0 !important;
      &::after {
        // content: " " !important;
        visibility: hidden;
      }
    }
    &__placeholder {
      margin: 0 !important;
      color: transparent !important;
      overflow: hidden;
      max-width: calc(100% - 60px);
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &__menu {
      // position: relative;
      &::before {
        position: absolute;
        content: " ";
        background-color: transparent;
        top: -9px;
        left: 0;
        width: 100%;
        height: 10px;
      }
      &::after {
        position: absolute;
        content: " ";
        background-color: transparent;
        top: 100%;
        left: 0;
        width: 100%;
        height: 10px;
      }
    }
    &__menu-list {
      font-size: 16px !important;
      max-height: 200px !important;
      overflow: hidden;
    }
    &__option {
      font-size: 14px !important;
      color: #494949 !important;
      background-color: transparent !important;
     
      &--is-focused {
        background-color: #d0e9d7 !important;
      }
       &--is-selected {
        background-color: var(--clr-primary-400) !important;
      }
    }
  }