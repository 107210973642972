@import "@scss/settings/functions.scss";


.landing-get {
  padding-top: 60px;
  padding-bottom: 50px;
  font-family: sans-serif;

  &__title {
    max-width: 600px;
    margin-bottom: 25px;
  }

  &__list {
    width: 100%;
    max-height: 450px;

    @media (max-width: em(992)) {
      margin-top: 15px;
      max-height: 400px;
    }

    @media (max-width: em(767)) {
      margin-top: 35px;
      max-height: initial;
    }
  }
  &__item {
    display: flex;
    align-items: center;

    justify-content: space-between;
    padding-left: 100px;
    padding-bottom: 30px;
    padding-right: 20px;
    @media (max-width: em(1200)) {
      padding-left: 70px;
    }

    @media (max-width: em(992)) {
      padding-left: 55px;
    }
    // @media (min-width: em(768)) {

    // }
    @media (max-width: em(767)) {
      padding-bottom: 36px;
      flex-direction: column;
      padding-left: initial;
      padding-right: initial;
    }

    &-info {
      margin-right: 15px;
      max-width: 370px;

      @media (max-width: em(767)) {
        margin-right: initial;
        max-width: initial;
      }
    }

    &-title {
      margin-bottom: 30px;
      font-size: 24px;
      font-weight: 900;
      line-height: 120%;
      color: var(--clr-primary-700);

      @media (max-width: em(767)) {
        text-align: center;
        font-size: 20px;
      }
    }

    &-img {
      max-width: 540px;

      img {
        border-radius: 20px;
        box-shadow: 0px 10px 15px rgba(202, 204, 216, 0.22);

        @media (max-width: em(767)) {
          margin-bottom: 25px;
        }
      }

      @media (max-width: em(767)) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        margin-right: initial;
      }
    }
    
  .section__text {
    margin-bottom: 25px;
    @media (max-width: em(767)) {
      margin-bottom: 0px;
    }
  }
  }

  .button {
    @media (max-width: em(460)) {
      width: 100%;
      max-width: initial;
    }
  }
}
