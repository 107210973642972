@import "@scss/settings/functions.scss";

.landing_hero {
  padding-top: 155px;
  padding-bottom: 100px;
  background-color: #f2f5f3;
  background-image: url("../../../../../assets/img/hero/bg.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: em(1200)) {
    padding-top: 85px;
  }
  @media (max-width: em(767)) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__info {
    width: calc(50% - 7.5px);
    @media (max-width: em(992)) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  h1 {
    font-size: 48px;
    font-weight: 900;
    line-height: 115%;
    margin-bottom: 30px;
    color: #3a4763;
    @media (max-width: em(1200)) {
      margin-bottom: 50px;
    }
    @media (max-width: em(767)) {
      font-size: 32px;
      margin-bottom: 40px;
    }
    i {
      font-style: inherit;
      color: var(--clr-primary-400);
    }
  }

  &__list {
    margin-bottom: 30px;
    @media (max-width: em(1200)) {
      margin-bottom: 40px;
    }
   
    @media (max-width: em(767)) {
      margin-bottom: 40px;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 15px;
      @media (max-width: em(1200)) {
        margin-bottom: 20px;
      }
      @media (max-width: em(767)) {
        margin-bottom: 20px;
      }
    }
    position: relative;
    padding-left: 55px;
    font-weight: 500;

    line-height: 100%;
    color: #3a4763;
    font-size: 24px;

    @media (max-width: em(767)) {
      padding-left: 30px;
      font-size: 18px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 11px;
      width: 35px;
      height: 1px;
      background-color: var(--clr-primary-400);

      @media (max-width: em(767)) {
        top: 7px;
        width: 20px;
      }
    }
  }

  .section__text {
    margin-bottom: 50px;
  }

  & .button {
    & svg {
      margin-left: 24px;}
  }

  &__img {
    position: initial;
    right: -150px;
    top: 50%;
    transform: initial;
    width: calc(50% - 7.5px);

    @media (max-width: em(992)) {
      width: 100%;
     
    }

    @media (max-width: em(767)) {
      display: none;
    }

    & .landing_hero {
      // ANIMATION

      &__modems {
        transform: translateY(25px);
        animation: wake-up var(--transition) linear 1.2s forwards;
      }

      &__modem {
        transform: translateY(-20px);
        // animation: modem var(--transition) linear forwards;
        animation: infinite modem-up var(--transition) linear alternate forwards;
        animation-duration: 2.7s;
        //
        &--2 {
          animation-delay: var(--transition);
        }

        &--3 {
          animation-delay: 0.6s;
        }

        &--4 {
          animation-delay: 0.9s;
        }
      }

      &__line {
        animation: dash 0.5s linear forwards;

        &--1 {
          stroke-dasharray: 540;
          stroke-dashoffset: -540;
          animation-delay: 1.2s;
        }

        &--2 {
          stroke-dasharray: 1300;
          stroke-dashoffset: 1300;
          animation-delay: 1.7s;
        }

        &--3 {
          stroke-dasharray: 420;
          stroke-dashoffset: 420;
          animation-delay: 2.8s;
        }

        &--4 {
          stroke-dasharray: 900;
          stroke-dashoffset: 900;
          animation-delay: 3.3s;
        }

        &--5 {
          stroke-dasharray: 950;
          stroke-dashoffset: 950;
          animation-delay: 4.3s;
        }
      }

      &__laptop {
        transform: translateY(25px);
        animation: wake-up var(--transition) linear 4.3s forwards;

        &-screen {
          fill: rgb(40, 40, 40);
          animation: screen var(--transition) ease-in 4.6s forwards;
        }

        &-apps {
          path {
            transform: scale(0);
            transform-box: fill-box;
            transform-origin: center;
            animation: apps var(--transition) ease-in 4.6s forwards;
          }
        }
      }

      &__processors {
        transform: translateY(25px);
        animation: wake-up var(--transition) linear 2.2s forwards;
      }

      &__processor {
        &--2 {
          transform: translateY(13px);
          animation: wake-up var(--transition) linear 2.5s forwards;
        }
      }
    }
  }

  .button {
    min-width: unset;
    min-height: 54px;
    width: max-content;
    max-width: 270px;
    white-space: nowrap;
  }
}

@keyframes modem {
  to {
    transform: translateY(0px);
  }
}
@keyframes modem-up {
  0% {
    transform: translateY(-20px);
  }
  10% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes wake-up {
  to {
    transform: translateY(0px);
  }
}

@keyframes screen {
  to {
    fill: #c6d3cc;
  }
}

@keyframes apps {
  to {
    transform: scale(1);
  }
}
