
@import "@scss/settings/functions.scss";

.landing-faq {
  padding-top: 45px;
  padding-bottom: 90px;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: em(767)) {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: em(767)) {
      flex-direction: column;
    }
  }

  &__info {
    margin-right: 15px;
    width: 100%;
    max-width: 480px;

    @media (max-width: em(767)) {
      margin-right: initial;
      max-width: initial;
      margin-bottom: 30px;
    }

  }

  .section__title {
    margin-bottom: 25px;
    @media (max-width: em(767)) {
      margin-bottom: 25px;
    }
  }

  &__text {
    margin-bottom: 35px;
    @media (max-width: em(767)) {
      margin-bottom: 15px;
    }
  }

  &__list {
    margin: 0;
    width: 100%;
    max-width: 570px;
    @media (max-width: em(767)) {
      max-width: initial;
    }


  }

  &__item {
    border-radius: 35px;
    &:not(:last-child) {
      margin-bottom: 27px;
    }
    @media (max-width: em(992)) {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    &-head {
      position: relative;
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      color: #56565c;
      cursor: pointer;
    }

    &-icon {
      position: relative;
      margin-left: 15px;
      border: 1px solid var(--clr-primary-400);
      border-radius: 50%;
      width: 100%;
      height: 24px;
      max-width: 24px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        background-color: var(--clr-primary-400);
        transform: translate(-50%, -50%);
      }

      &::before {
        width: 10px;
        height: 1.5px;
      }

      &::after {
        width: 1.5px;
        height: 10px;
        transition: height var(--transition);
      }
    }

    &-body {
      overflow: hidden;
      max-height: 0;
      box-sizing: content-box;
      line-height: 160%;
      transition: max-height var(--transition), padding-top var(--transition);
      will-change: max-height;
    }

    &--active {
      .landing-faq__item-icon {
        &::after {
          height: 0;
          transition: height var(--transition);
        }
      }

      .landing-faq__item-body {
        padding-top: 10px;
        max-height: 100%;
        transition: max-height var(--transition), padding-top var(--transition);
      }
    }
  }
}
