.quill-editor {
    .ql-container {
        min-height: 300px;
        height: 100%;
        resize: vertical;
        overflow: auto;
    }
    &__dark {
        .ql-stroke {
            stroke: #b4b7bd !important;
        }
        .ql-fill {
            fill: #b4b7bd !important;
        }
        .ql-picker-label {
            color: #b4b7bd !important;
        }
    }
}
