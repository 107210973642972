.app-select {
    &__dropdown {
        position: relative;
        width: 100%;
        &-btn {
            display: flex;
            min-width: 110px;
            align-items: center;
            justify-content: space-between;
            padding: 3px 8px;
            width: 100%;
            height: 38px;
            border-radius: 0.357rem;
            border: 1px solid #ccc;
            &-invalid {
                border-color: #ea5455 !important;
            }
            &-placeholder {
                color: #6e6b7b;
            }
            &-icon {
                color: #cccccc;
                fill: #cccccc;
            }
            &-text {
                width: 100%;
                max-width: calc(100% - 30px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #6e6b7b;
            }
            &:hover,
            &:focus {
                border-color: #7367f0;
            }
            &:disabled {
                .app-select__dropdown-btn-text {
                    color: #6e6b7b;
                }
                .app-select__dropdown-btn-icon {
                    color: #6e6b7b;
                    fill: #6e6b7b;
                }
                &:hover,
                &:focus {
                    border-color: #ccc;
                }
            }
        }
        &-indicator {
            padding: 0 4px;
            color: #cccccc;
            fill: #cccccc;
        }
        &-menu {
            width: 100%;
            background-color: #fff;
            border-radius: 4px;
            margin-top: 8px;
            position: absolute;
            z-index: 2;
            box-shadow:
                0 0 0 1px #0d16261a,
                0 4px 11px #0d16261a;
        }
        &-blanket {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }

    & .select {
        &__control {
            &:hover,
            &:focus,
            &:focus-within {
                border-color: #ccc !important;
                box-shadow: none !important;
            }
        }
    }
}

.dark-layout {
    .app-select {
        &__dropdown {
            &-btn {
                border-color: #404656;
                &-placeholder {
                    color: #6e6b7b;
                }
                &-icon {
                    color: #cccccc;
                    fill: #cccccc;
                }
                &-text {
                    color: #d0d2d6;
                }
                &:hover,
                &:focus {
                    border-color: #7367f0;
                }
                &:disabled {
                    .app-select__dropdown-btn-text {
                        color: #6e6b7b;
                    }
                    .app-select__dropdown-btn-icon {
                        color: #6e6b7b;
                        fill: #6e6b7b;
                    }
                    &:hover,
                    &:focus {
                        border-color: #404656;
                    }
                }
            }
            &-indicator {
                color: #cccccc;
                fill: #cccccc;
            }
            &-menu {
                background-color: #283046;
                box-shadow:
                    0 0 0 1px #0d16261a,
                    0 4px 11px #0d16261a;
            }
        }

.select {
    &__control {
        &:hover,
        &:focus,
        &:focus-within {
            border-color: #404656 !important;
            box-shadow: none !important;
        }}
    }
    }
}
