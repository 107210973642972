
@import "@scss/settings/functions.scss";
.landing-modal-lite {
  -webkit-transition: 0.3s;
  transition: 0.3s;

  & .landing-modal__content {
    position: absolute;
    top: 0%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: 0.3s;
      transition: 0.3s;
  }

  &.showModal {
    opacity: 1;
    visibility: visible;
  }
  &.showModal .landing-modal__content {
    opacity: 1;
    top: 50%;
  }
}

.landing-modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(58, 71, 99, 0.6);
  opacity: 0;
  visibility: hidden;

  &.entering {
    animation: showModal var(--duration) forwards;

    .landing-modal__content {
      animation: showModalContent var(--duration) forwards;
    }
  }

  &.entered {
    opacity: 1;
    visibility: visible;

    .landing-modal__content {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.exiting {
    animation: showModal var(--duration) reverse;

    .landing-modal__content {
      animation: showModalContent var(--duration) reverse;
    }
  }

  &.exited {
    opacity: 0;
    visibility: hidden;

    .landing-modal__content {
      opacity: 0;
      transform: translateY(-100%);
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px 15px;
    min-height: 100%;
  }

  &__content {
    position: relative;
    border-radius: 7px;
    padding: 50px 30px 30px;
    width: 100%;
    max-width: var(--width);
    box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.22);
    color: var(--clr-default-900);
    background-color: var(--clr-default-100);

    @media (max-width: em(767)) {
      padding: 35px 15px 20px;
    }

    &-title {
      position: relative;
      margin-bottom: 20px;
      font-weight: 900;
      font-size: 24px;
      line-height: 120%;
      color: #45454a;
      @media (max-width: em(767)) {
        font-size: 18px;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: -15px;
        width: 78px;
        height: 6px;
        background-color: var(--clr-primary-400);
      }
    }
  }

  &__close {
    position: absolute;
    right: 20px;
    top: -50px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 50px;
    height: 50px;
    background-color: var(--clr-primary-400);

    @media (max-width: em(767)) {
      top: -40px;
      width: 40px;
      height: 40px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 34px;
      height: 34px;
      background-image: url("data:image/svg+xml,%3Csvg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16.9706' cy='17' r='11.25' transform='rotate(-45 16.9706 17)' stroke='white' stroke-width='1.5'/%3E%3Crect x='12.728' y='19.8284' width='10' height='1.5' transform='rotate(-45 12.728 19.8284)' fill='white'/%3E%3Crect x='13.7886' y='12.7573' width='10' height='1.5' transform='rotate(45 13.7886 12.7573)' fill='white'/%3E%3C/svg%3E");
      transform: translate(-50%, -50%);
    }
  }

  &__input {
    flex: 1 1 50%;
    input,
    textarea {
      border: initial;
      // background-color: #f5f5f5;
    }

    textarea {
      min-height: 100px;
    }
  }

  &__input + &__input {
    margin-top: 20px;

    @media (max-width: em(767)) {
      margin-top: 10px;
    }
  }

  &__text {
    line-height: 160%;
    color: var(--clr-text);

    p + p {
      margin-top: 25px;
      @media (max-width: em(767)) {
        margin-top: 15px;
      }
    }
  }

  &__list {
    display: grid;
    grid-gap: 10px 90px;
    grid-template-columns: repeat(2, 1fr);
    margin: 30px auto;
    max-width: 520px;

    @media (max-width: em(767)) {
      grid-gap: 10px;
      margin: 15px auto;
    }

    @media (max-width: em(576)) {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: initial;
      max-width: initial;
    }

    li {
      position: relative;
      padding-left: 35px;
      line-height: 160%;
      color: var(--clr-text);

      @media (max-width: em(767)) {
        padding-left: 25px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        background-image: url("../../../../assets/img/ui/done.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @media (max-width: em(767)) {
          top: 4px;
          width: 16px;
          height: 16px;
        }
      }
    }

    li + li {
      @media (max-width: em(576)) {
        margin-top: 5px;
      }
    }
  }

  .button {
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: em(767)) {
      margin-top: 15px;
    }
  }
}

@keyframes showModal {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes showModalContent {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
