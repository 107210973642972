// @import "../node_modules/prismjs/themes/prism-tomorrow";

.blur {
  filter: blur(10px);
}

.blur-box {
  filter: blur(5px);
  cursor: default !important;
  & * {
    cursor: default !important;
  }
}

a {
  text-decoration: none !important;
}
