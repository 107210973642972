
@import "@scss/settings/functions.scss";

.landing-form__item {
  &-input {
    // position: relative;
    width: 100%;
    &-error {
      & input,
      & textarea {
        border-color: #f46767 !important;
      }
    }
    &-label {
      position: relative;
      width: 100%;
      margin: 0;
      &:focus-within {
        & input,
        & textarea {
          &:placeholder-shown + .landing-form__item-input-label-name {
            top: 0px;
            font-size: 12px;
            transform: translateY(-50%);
            transition: top 0.3s, font-size 0.3s;
            background-color: var(--clr-default-100);
            border-radius: 5px;
          }
        }

        & input,
        & textarea {
          &::placeholder {
            font-size: 16px;
            // color: #8a8a8a;
          }
        }
      }
      &-name {
        position: absolute;
        overflow: hidden;
        max-width: 80%;
        white-space: nowrap;
        text-overflow: ellipsis;
        top: 0px;
        left: 16px;
        font-size: 12px;
        padding: 0 5px;
        transform: translateY(-50%);
        transition: top 0.3s, font-size 0.3s;
        background-color: var(--clr-default-100);
        border-radius: 5px;
        &-required {
          color: #f46767;
          margin-left: 5px;
        }
      }
    }

    &-icon {
      position: absolute;
      cursor: pointer;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }
    & input {
      &:placeholder-shown + .landing-form__item-input-label-name {
        top: 50%;

        font-size: 16px;
        // opacity: 0;
        transition: top 0.3s, font-size 0.3s, opacity 0.3s;
      }
    }

    & textarea {
      &:placeholder-shown + .landing-form__item-input-label-name {
        top: 24px;
        transform: translateY(-50%);
        font-size: 16px;
        // opacity: 0;
        transition: top 0.3s, font-size 0.3s, opacity 0.3s;
      }
    }

    & input,
    & textarea {
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      padding: 16px;
      width: 100%;
      min-height: 60px;
      color: #494949;
      font-size: 16px;
      @media (max-width: em(767)) {
        padding: 10px;
        min-height: 45px;
      }
      &::placeholder {
        color: transparent;
      }
      &:focus {
        outline: none;
      }
    }

    & textarea {
      width: 100%;
      min-height: 160px;
      resize: none;
      &::placeholder {
        color: transparent;
      }
      @media (max-width: em(767)) {
        min-height: 120px;
      }
    }

    & input:-webkit-autofill,
    & input:-webkit-autofill:hover,
    & input:-webkit-autofill:focus,
    & textarea:-webkit-autofill,
    & textarea:-webkit-autofill:hover,
    & textarea:-webkit-autofill:focus,
    & select:-webkit-autofill,
    & select:-webkit-autofill:hover,
    & select:-webkit-autofill:focus {
      border: 1px solid #e0e0e0;
      -webkit-text-fill-color: #494949;
      -webkit-box-shadow: 0 0 0px 1000px var(--clr-default-100) inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

input:-webkit-autofill + .landing-form__item-input-label-name,
textarea:-webkit-autofill + .landing-form__item-input-label-name {
  top: 0px;
  font-size: 12px;
  transform: translateY(-50%);
  transition: top 0.3s, font-size 0.3s;
  background-color: var(--clr-default-100);
  border-radius: 5px;
}
