@import "@scss/settings/functions";

.landing-menu {
  &__list {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 0;
    list-style: none;
    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 30px;
    }

    @media (max-width: em(576)) {
      margin-bottom: 20px;
    }

    @media (max-width: em(460)) {
      flex-direction: column;
    }
  }

  &__link {
    position: relative;
    display: block;
    line-height: 125%;
    color: #80868f;
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -2px;
      width: 0;
      height: 1.5px;
      background-color: #80868f;
      transform: translateX(-50%);
      transition: width var(--transition);
    }

    @media (hover) {
      &:hover,
      &:focus {
        color: #80868f;
        &::before {
          width: 100%;
          transition: width var(--transition);
        }
      }
    }
    &:active {
      color: #80868f;
    }
  }

  &__item + &__item {
    margin-left: 30px;

    @media (max-width: em(1200)) {
      margin-left: 15px;
    }

    @media (max-width: em(767)) {
      margin-left: 30px;
    }

    @media (max-width: em(460)) {
      margin-top: 15px;
      margin-left: initial;
    }
  }
}
