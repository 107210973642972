.landing__send-request {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .button {
            // width: 100%;
            color: var(--clr-primary-400);
            background-color: var(--clr-default-100);
            transition: color var(--transition), background-color var(--transition);

            @media (max-width: em(576)) {
                min-width: initial;
            }

            @media (hover) {
                &:hover {
                    color: var(--clr-default-100);
                    background-color: var(--clr-primary-400);
                    transition: color var(--transition), background-color var(--transition);
                }
            }
        }
    }
    &__text {
        margin-bottom: 20px;
        text-align: center;
    }
    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
      
        &-inputs {
            width: 100%;
           margin-bottom: 30px;
        }
        &-input { &:not(:last-child){margin-bottom: 16px;}}
        &-label {margin-bottom: 8px;}
        .button {margin: 0;}
    }
}
