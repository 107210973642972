// @import
a {
  &:focus {
    outline: none;
  }
}

a:not([href]) {
  color: inherit;
  text-decoration: none;

  // @include hover() {
  //   color: inherit;
  //   text-decoration: none;
  // }
}

hr {
  opacity: unset !important;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
