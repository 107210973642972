@import "@scss/settings/functions.scss";

.landing-use {
  padding-top: 55px;
  padding-bottom: 50px;
  font-size: 16px;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 40px;
      max-width: initial;
    }
    .section__title {
      margin-right: 15px;
      max-width: 480px;

      @media (max-width: em(992)) {
        margin-right: initial;
        margin-bottom: 20px;
        max-width: initial;
      }
    }

    @media (max-width: em(992)) {
      flex-direction: column;
      align-items: initial;
    }
  }

  &__tabs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: -10px;
    @media (max-width: em(992)) {
      align-self: initial;
    }
  }

  &__tab {
    margin: 10px;
    .button {
      min-width: unset !important;
      white-space: nowrap;
    }
    &--active {
      .button {
        color: var(--clr-primary-400);
        background-color: var(--clr-default-100);
      }
    }
  }

  &__content {
    margin: 0 auto;

    @media (min-width: em(1200)) {
      max-width: 1200px;
    }
    .swiper {
      padding: 0 15px;
    }

    .swiper-wrapper {
      height: unset;
      margin-bottom: 55px;
    }

    &-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 55px;

      &-tariff {
        justify-content: center;
        margin: 0;

        @media (min-width: em(768)) {
          .swiper-wrapper {
            justify-content: center;
          }
        }
      }
      @media (max-width: em(1200)) {
        margin: 0;
      }
    }

    &-item {
      &:not(:last-child) {
        @media (min-width: em(1200)) {
          margin-right: 30px;
        }
      }

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 7px;
      padding: 25px;
      box-shadow: 0px 15px 15px rgba(202, 204, 216, 0.32);
      transition: opacity 0.6s;
      height: auto;
      @media (max-width: em(576)) {
        padding: 15px;
        opacity: 0.5;
        transition: opacity 0.6s;
        pointer-events: none;
      }

      &.swiper-slide-active {
        @media (max-width: em(576)) {
          opacity: 1;
          transition: opacity 0.6s;
          pointer-events: initial;
        }
      }

      &-title {
        margin: 0;
        font-weight: 700;
        font-size: 26px;
        line-height: 120%;
        text-align: center;
        color: var(--clr-primary-700);

        &-inner {
          display: flex;
          align-items: center;
          justify-content: center;
          // margin-top: 5px;
          margin-bottom: 10px;
          // border-radius: 30px;
          padding: 0 5px;
          min-height: 50px;
          background-color: #edf5f2;
        }
      }

      &-img {
        position: relative;
        // z-index: 1;
        height: 200px;
        // width: 210px;
        align-self: center;
        margin-bottom: 4px;
      }
      &-circle {
        position: absolute;
        // z-index: -1;
        top: 50%;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        color: #fff;

        text-align: center;
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;

        // color: #ffffff;

        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: var(--clr-primary-400);
        transform: translateY(-50%);
      }
      &-type {
        margin-bottom: 10px;
        line-height: 160%;
        text-align: center;
        min-height: 26px;
      }

      &-price {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &--old {
          position: absolute;
          left: 0;
          top: 50%;
          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
          text-decoration-line: line-through;
          transform: translateY(-50%);
        }

        &--new {
          font-weight: 900;
          font-size: 40px;
          line-height: 120%;
          color: var(--clr-primary-700);
          @media (max-width: em(767)) {
            font-size: 30px;
          }
        }
      }

      .button {
        width: 100%;
        color: var(--clr-primary-400);
        background-color: var(--clr-default-100);
        transition:
          color var(--transition),
          background-color var(--transition);

        @media (max-width: em(576)) {
          min-width: initial;
        }

        @media (hover) {
          &:hover {
            color: var(--clr-default-100);
            background-color: var(--clr-primary-400);
            transition:
              color var(--transition),
              background-color var(--transition);
          }
        }
      }

      &__link {
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border: 2px solid var(--clr-primary-400);
        border-radius: 3px;

        padding-right: 25px;
        padding-left: 25px;
        min-width: 210px;
        min-height: 54px;
        max-width: 240px;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: var(--clr-primary-400);
        background-color: var(--clr-default-100);
        transition:
          color var(--transition),
          background-color var(--transition);

        @media (max-width: em(767)) {
          margin-top: 30px;
        }
        @media (hover) {
          &:hover,
          &:focus {
            color: var(--clr-default-100);
            background-color: var(--clr-primary-400);
            transition:
              color var(--transition),
              background-color var(--transition);
          }
        }
      }

      &-list {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        padding: 20px 0 30px;
        li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          position: relative;
          padding-left: 35px;
          line-height: 160%;

          @media (max-width: em(767)) {
            padding-left: 25px;
          }

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            background-image: url("../../../../../assets/img/ui/done.svg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @media (max-width: em(767)) {
              top: 4px;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
    &-item-tariff {
      padding: 30px;
      // @media (min-width: em(570)) {
      //   width: 300px !important;
      // }
      & .btn-contact-us {
        margin-bottom: 20px;
      }

      & .tariff-info-box {
        position: relative;
        padding-top: 36px;
        &-image {
          position: absolute;
          z-index: -1;
          top: 0;
        }
      }

      &-title {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 120%;

        color: var(--clr-primary-700);
        margin-bottom: 6px;
        &-enterprise {
          margin-bottom: 72px;
        }
      }
      &-price {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin-bottom: 16px;
        margin-right: auto; // border-radius: 30px;
        padding-right: 10px;
        padding-left: 10px;
        min-height: 50px;
        background-color: #edf5f2;

        &-amount {
          margin-right: 8px;
          font-weight: 700;
          font-size: 26px;
          line-height: 120%;
          text-align: center;
          color: var(--clr-primary-700);
        }
      }
      &-list {
        overflow: hidden;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        max-height: 275px;
        margin-bottom: 35px;
        transition: max-height 0.3s;
        li {
          position: relative;
          padding-left: 35px;
          line-height: 160%;
          &:not(:last-child) {
            margin-bottom: 25px;
          }
          @media (max-width: em(767)) {
            padding-left: 25px;
          }

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            background-image: url("../../../../../assets/img/ui/done.svg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @media (max-width: em(767)) {
              top: 4px;
              width: 16px;
              height: 16px;
            }
          }
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: calc(100% + 12px);
            width: 100%;
            height: 1px;
            background-color: #f3f3f4;

            @media (max-width: em(767)) {
            }
          }
        }
      }
      &-all-func {
        padding: 20px 0;
        margin-right: auto;
        &-btn {
          max-height: 25px;
          margin-right: auto;
          font-weight: 400;
          font-size: 16px;
          line-height: 160%;
          text-decoration-line: underline;
          color: var(--clr-primary-400);
        }
      }
    }
  }
}
