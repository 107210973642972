@import "@scss/settings/functions";

.landing-lang {
  position: relative;
  margin-left: 20px;
  border-radius: 5px;
  box-shadow: 0px 10px 10px rgba(36, 36, 40, 0.05);
  text-transform: capitalize;
  background-color: var(--clr-default-100);
  line-height: 1.45;
  font-family: "Montserrat", sans-serif !important;
  color: inherit;
  @media (max-width: em(1200)) {
    margin-left: 10px;
  }

  @media (max-width: em(992)) {
    margin-left: initial;
  }

  &__current {
    position: relative;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    color: inherit;
    background-color: var(--clr-default-100);
    cursor: pointer;

    &::after {
      content: "";
      margin-left: 8px;
      width: 11px;
      height: 6px;
      background-image: var(--arrow);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transform: rotate(0);
      transition: transform var(--transition);
      pointer-events: none;
    }
    &:focus {
      &::after {
        transform: rotate(180deg);
        transition: transform var(--transition);
      }
    }
  }

  &__list {
    position: absolute;
    left: 50%;
    z-index: 10;
    border-radius: 4px;
    margin: 0;
    padding: 5px 0;
    width: 100%;
    margin: 2px 0;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
    background-color: var(--clr-default-100);
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%);
    transition:
      opacity var(--transition),
      visibility var(--transition);

    @media (max-width: em(992)) {
      overflow-y: auto;
      max-height: 100px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    width: 100%;
    color: inherit;
    transition: background-color var(--transition);

    @media (hover) {
      &:hover,
      &:focus {
        color: inherit;
        background-color: #d0e9d7;
        transition: background-color var(--transition);
      }
    }

    &--active {
      color: inherit;
      background-color: #d0e9d799;
      transition: background-color var(--transition);
    }
  }

  img {
    margin-right: 6px;
    width: 21px;
    height: 15px;
  }

  &:hover,
  &:focus,
  &:focus-within {
    .landing-lang__list {
      opacity: 1;
      visibility: visible;
      transition:
        opacity var(--transition),
        visibility var(--transition);
    }

    .landing-lang__current::after {
      transform: rotate(180deg);
      transition: transform var(--transition);
    }
  }
}
