// var for box shadow of gradient buttons
// Buttons
.btn {
  box-shadow: none;
  font-weight: 500;

  // gradient button on hover transition
  &[class*="bg-gradient-"] {
    transition: all 0.2s ease;
    &:hover {
      transform: translateY(-2px);
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.65 !important;
    &[class*="btn-flat-"] {
      background-color: transparent !important;
    }
    &.btn-flat-primary {
      color: $primary !important;
    }
    &.btn-flat-secondary {
      color: $secondary !important;
    }
    &.btn-flat-success {
      color: $success !important;
    }
    &.btn-flat-danger {
      color: $danger !important;
    }
    &.btn-flat-warning {
      color: $warning !important;
    }

    &.btn-flat-info {
      color: $info !important;
    }
    &.btn-flat-light {
      color: $light !important;
    }
    &.btn-flat-dark {
      color: $dark !important;
    }
  }
  // Icon button padding
  &.btn-icon {
    padding: 0.715rem 0.736rem;
  }
  &.btn-sm {
    &.btn-icon {
      padding: 0.5rem;
      line-height: 0.5;
    }
  }
  &.btn-lg {
    &.btn-icon {
      padding: 1rem;
      line-height: 0.75;
    }
  }

  .badge {
    top: 0;
  }

  &:focus,
  &.focus,
  &:active,
  &.active {
    outline: none;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: none !important;
  }

  // feather icons inside btn
  .feather {
    vertical-align: bottom;
  }
}

// For Waves Input Padding
.btn.waves-input-wrapper {
  padding: 0;
}

@include media-breakpoint-down(sm) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
}

// Remove cursor-pointer from button if button is disabled
// * setting it to inherit will auto adept cursor
.waves-effect {
  cursor: inherit;
}
