@import "@scss/settings/functions.scss";

.landing-sign {
  &__list {
    display: flex;
    align-items: center;
    margin: 0;
    @media (max-width: em(992)) {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    @media (max-width: em(576)) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &__item {
    position: relative;
    display: block;
    color: #80868f;
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -2px;
      width: 0;
      height: 1.5px;
      background-color: #80868f;
      transform: translateX(-50%);
      transition: width var(--transition);
    }

    @media (hover) {
      &:hover,
      &:focus {
        color: #80868f;
        &::before {
          width: 100%;
          transition: width var(--transition);
        }
      }
      &:active {
        color: #80868f;
      }
    }

    &:last-child {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: -12.5px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #80868f;
      }
    }
  }

  &__link {
    color: inherit;
    @media (hover) {
      &:hover {
        color: #80868f;
      }
    }
  }

  &__item + &__item {
    margin-left: 25px;
  }
}
