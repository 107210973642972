@import "@scss/settings/functions.scss";

.landing-burger {
  &__btn {
    position: relative;
    z-index: 15;
    display: block;
    width: 30px;
    height: 16px;
    background-color: transparent;

    @media (min-width: em(1200)) {
      display: none;
    }

    &::after,
    &::before,
    span {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #3a4763;
    }

    &::before {
      top: 0;
      transition:
        transform var(--transition),
        top var(--transition);
    }

    &::after {
      bottom: 0;
      transition:
        transform var(--transition),
        bottom var(--transition);
    }

    span {
      top: 7px;
      width: 25px;
      transition: transform var(--transition);
    }

    &--active {
      span {
        transform: scale(0);
        transition: transform var(--transition);
      }

      &::before {
        top: 7px;
        transform: rotate(45deg);
        transition:
          transform var(--transition),
          top var(--transition);
      }

      &::after {
        bottom: 7px;
        transform: rotate(-45deg);
        transition:
          transform var(--transition),
          bottom var(--transition);
      }
    }
  }
}
