
@import "@scss/settings/functions.scss";

.landing-form__item {
  &-selectV2 {
    position: relative;
    width: 100%;
    &__dropdown-btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      min-height: 48px;
      color: #494949 !important;
      font-family: "Montserrat", sans-serif !important;
      font-size: 16px;
      padding: 8px 8px 8px 16px;
      width: 100%;

      @media (max-width: em(767)) {
        padding: 10px;
      }
      &-text {
        display: flex;
        align-items: center;
        width: calc(100% - 30px);

        & .country-name {
          display: inline-block;
          max-width: 250px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          @media (max-width: em(480)) {
            max-width: 220px;
          }
          @media (max-width: em(360)) {
            max-width: 180px;
          }
        }
      }
      &-icon {
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        padding-left: 8px;
        // margin-left: auto;
        color: #ccc;
        &:hover {
          color: #999999;
        }
        &::before {
          position: absolute;
          left: -1px;
          content: " ";
          height: 30px;
          width: 1px;
          background-color: #ccc;
        }
      }
      &-error {
        border-color: #f46767;
      }

      &:focus,
      &[name="active"] {
        & .landing-form__item-select__label-name {
          top: 0px;
          opacity: 1;
          font-size: 12px;
          transform: translateY(-50%);
          transition:
            top 0.3s,
            font-size 0.3s;
        }
      }
    }
    &__label {
      position: relative;
      width: 100%;
      margin: 0;

      &-name {
        position: absolute;
        overflow: hidden;
        max-width: calc(100% - 60px);
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        top: 50%;
        left: 16px;
        font-size: 16px;
        background-color: var(--clr-default-100);
        color: #5e5873 !important;
        font-family: "Montserrat", sans-serif !important;
        padding: 0 5px;
        transform: translateY(-50%);
        transition:
          top 0.3s,
          font-size 0.3s;

        // opacity: 0;
        &-active {
          opacity: 1;
          top: 0px;
          font-size: 12px;
          transform: translateY(-50%);

          transition:
            top 0.3s,
            font-size 0.3s;
        }
        &-disabled {
          background-color: transparent;
        }
        &-required {
          color: #f46767;
          margin-left: 5px;
        }
      }
    }
  }
  &-error {
    // margin-top: 6px;
    color: #f46767;
    font-size: 14px;
    @media (max-width: em(767)) {
      font-size: 12px;
    }
  }
}

.landing__form__selectV2 {
  &__error-message {
    color: #f46767;
    font-size: 14px;
    @media (max-width: em(767)) {
      font-size: 12px;
    }
  }
  &-error {
    & .landing__form__selectV2__control {
      border-color: #f46767 !important;
    }
  }

  &__control {
    // padding: 16px;
    border-color: #e0e0e0 !important;
    // min-height: 48px !important;
    max-height: 40px !important;
    font-size: 16px !important;
    & input {
      min-height: auto !important;
      padding: 0px !important;
    }
    &--menu-is-open {
      // border-color: #f46767 !important;
      box-shadow: none !important;
    }

    &--is-focused {
      border-color: #e0e0e0 !important;
    }
    &:hover,
    &:focus,
    &:focus-within {
      border-color: #e0e0e0 !important;
      box-shadow: none !important;
    }
  }
  &__value-container {
    padding: 7px 15px !important;
    color: transparent !important;
  }
  &__single-value {
    display: flex;
    align-items: center;
  }
  &__input-container {
    padding: 0 !important;
    margin: 0 !important;
    &::after {
      // content: " " !important;
      visibility: hidden;
    }
  }
  &__placeholder {
    margin: 0 !important;
    // color: transparent !important;
    overflow: hidden;
    // max-width: calc(100% - 0px);
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__menu {
    position: relative;
    box-shadow: none !important;
    &::before {
      position: absolute;
      content: " ";
      background-image: linear-gradient(to top, #ffffff00, #fff);
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
    }
    &::after {
      position: absolute;
      content: " ";
      background-image: linear-gradient(to bottom, #ffffff00, #fff);
      z-index: 3;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
    }
  }
  &__menu-list {
    font-size: 16px !important;
    // max-height: 200px !important;
    // overflow: hidden;
  }
  &__option {
    font-size: 14px !important;
    color: #494949 !important;
    background-color: transparent !important;

    &--is-focused {
      background-color: #d0e9d7 !important;
    }
    &--is-selected {
      background-color: var(--clr-primary-400) !important;
    }
  }
}

.select__dropdown {
  &-indicator {
    padding: 0 8px;
    color: #ccc;
  }
  &-menu {
    max-width: 100%;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 8px;
    position: absolute;
    z-index: 2;
    box-shadow:
      0 0 0 1px #0d16261a,
      0 4px 11px #0d16261a;
  }
  &-blanket {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}
