@import "@scss/settings/functions.scss";

.landing-dropdown {
  position: absolute;
  left: 0;
  // top: 75px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 30px 15px;
  width: 100%;
  height: max-content;
  background-color: var(--clr-default-100);
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition:
    opacity var(--transition) ease-in-out,
    visibility var(--transition) ease-in-out,
    transform var(--transition) ease-in-out,
    background-color var(--transition) ease-in-out;
  pointer-events: none;

  @media (max-width: em(992)) {
    flex-direction: column;
  }

  @media (max-width: em(767)) {
    padding: 20px 15px 30px;
  }

  @media (min-width: em(1200)) {
    display: none !important;
  }

  &.landing-dropdown--active {
    transition:
      opacity var(--transition) ease-in-out,
      visibility var(--transition) ease-in-out,
      transform var(--transition) ease-in-out,
      background-color var(--transition) ease-in-out;

    & + .landing-dropdown__overlay {
      opacity: 1;
      visibility: visible;
    }
  }

  &--active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    pointer-events: auto;
  }
  &__overlay {
    position: fixed;
    width: 100%;
    left: 0;
    top: 150px;
    bottom: 0;
    z-index: -1;
    background-color: rgba(58, 71, 99, 0.8);
    // background-color: transparent;
    opacity: 0;
    visibility: hidden;
    transition:
      opacity var(--transition),
      visibility var(--transition);

    @media (min-width: em(1200)) {
      display: none;
    }
  }
}
