
@import "@scss/settings/functions.scss";

.landing-support {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #4bc776;
  background-image: url("../../../../../assets/img/support/bg.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: em(576)) {
    padding-bottom: initial;
  }

  &__wrapper {
    display: flex;
    align-items: center;

    justify-content: space-between;

    @media (max-width: em(767)) {
      flex-direction: column;
    }
  }

  .section__title {
    color: var(--clr-default-100);
    font-size: 48px;
    line-height: 115%;
    margin-bottom: 16px;
    &::before {
      background-color: var(--clr-default-100);
    }
  }

  &__text {
    margin-bottom: 80px;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: var(--clr-default-100);

    @media (max-width: em(1200)) {
      margin-bottom: 30px;
    }
  }

  &__list {
    display: grid;
    row-gap: 40px;
    column-gap: 40px;

    grid-template-columns: repeat(2, 1fr);

    @media (max-width: em(1200)) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px;
      column-gap: 20px;
    }

    @media (max-width: em(767)) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: em(460)) {
      display: block;
    }
  }

  &__info {
    width: 100%;
    max-width: 560px;
    margin-right: 45px;
    @media (max-width: em(767)) {
      max-width: initial;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__item {

    &-link {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 160%;
      color: var(--clr-default-100);
      @media (max-width: em(767)) {
        font-size: 14px;
      }
      p {
        position: relative;
        white-space: nowrap;
        margin: 0;
        color: var(--clr-default-100);
        &::before {
          content: "";
          position: absolute;
          left: 50%;
          bottom: -2px;
          width: 0;
          height: 1.5px;
          background-color: var(--clr-default-100);
          transform: translateX(-50%);
          transition: width var(--transition);
        }
      }

      @media (hover) {
        &:hover {
          p {
            color: var(--clr-default-100);
            &::before {
              width: 100%;
              transition: width var(--transition);
            }
          }
        }
      }
    }

    &-img {
      margin-right: 20px;
      max-width: 52px;
      max-height: 52px;

      @media (max-width: em(767)) {
        margin-right: 10px;
        max-width: 35px;
        max-height: 35px;
      }

      svg {
        @media (max-width: em(767)) {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  &__item + &__item {
    @media (max-width: em(460)) {
      margin-top: 15px;
    }
  }

  // FORM

  &__form {
    border-radius: 8px;
    padding: 50px 45px;
    width: 100%;
    max-width: 570px;
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.15);
    background-color: var(--clr-default-100);

    @media (max-width: em(992)) {
      padding: 25px 20px;
    }

    @media (max-width: em(767)) {
      margin-left: initial;
    }

    @media (max-width: em(576)) {
      border-radius: initial;
      padding: 25px 15px;
      min-width: calc(100% + 30px);
    }
  }


  &__input {
    &_textarea {
      margin-bottom: 16px;
       & input { min-height: 160px;}
     
    }
    margin-bottom: 20px;
  }

  // CHECKBOX

  &__checkbox {
    margin-bottom: 32px;

    @media (max-width: em(767)) {
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }

  .button {
    width: 100%;
    max-width: initial;
  }
}
