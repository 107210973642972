@import "./settings/functions";
// @import "./settings/fonts";
@import "./settings/normalize";
@import "./variables";

body {
  // font-size: 1.6rem;
  color: var(--clr-text);

  &.lock {
    // overflow: hidden;
    width: 100%;
    position: fixed;
    -ms-touch-action: none;
    touch-action: none;
    overscroll-behavior: none;

    // .landing-wrapper::before {
    //   opacity: 1;
    //   visibility: visible;
    //   transition: opacity var(--transition), visibility var(--transition);
    // }
  }
}
.landing-hidden {
  display: none !important;
}

.landing-wrapper {
  display: flex !important;
  flex-direction: column;
  // overflow: hidden;
  width: 100%;
  min-width: 20rem;
  min-height: 100%;
  font-family: "Montserrat", sans-serif !important;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   // bottom: 0;
  //   z-index: 5;
  //   background-color: rgba(58, 71, 99, 0.8);
  //   opacity: 0;
  //   visibility: hidden;
  //   transition: opacity var(--transition), visibility var(--transition);
  // }

  & button {
    font-family: "Roboto", sans-serif;
  }

  & a {
    &:active {
      color: inherit;
    }
  }
}
button:focus {
  outline: none;
}

main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

// // <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================
// @import "./components/ui.scss";

// // <ПОДКЛЮЧЕНИЕ БИБЛИОТЕК>===========================
// @import "./libs/swiper.scss";
