@import "@scss/settings/functions.scss";

.landing-header {
  // font-family: sans-serif;
  font-size: 16px;
  position: absolute;
  z-index: 10;
  padding: 25px 0;
  width: 100%;
  opacity: 1;
  visibility: visible;
  transition:
    background-color var(--transition),
    opacity var(--transition),
    visibility var(--transition);
  @media (max-width: em(1199)) {
    padding: 15px 0;
    position: fixed;
    top: 0;
    background-color: var(--clr-default-100);
    @supports ((position: sticky) or (position: -webkit-sticky)) {
      position: -webkit-sticky;
      position: sticky;
      // width: 100vw;
    }
  }
  &-hidden {
    opacity: 0;
    visibility: hidden;
    transition:
      opacity var(--transition),
      visibility var(--transition);
    -webkit-transition:
      opacity var(--transition),
      visibility var(--transition);
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(1199)) {
      .landing-lang,
      .landing-sign__list,
      .landing-menu,
      .landing-header__btn {
        display: none;
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-right: 15px;
    margin-left: 15px;
    border-radius: 3px;
    padding-right: 20px;
    padding-left: 20px;
    min-width: 145px;
    min-height: 48px;
    max-width: 1180px;
    font-weight: 500;
    color: var(--clr-primary-400);
    background-color: #d0e9d7;
    transition:
      color var(--transition),
      background-color var(--transition);

    @media (max-width: em(767)) {
      margin-right: initial;
      margin-left: initial;
    }

    &:hover {
      color: var(--clr-default-100);
      background-color: var(--clr-primary-400);
      transition:
        color var(--transition),
        background-color var(--transition);
    }
  }
}
