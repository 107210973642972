

.section {
  &__title {
    position: relative;
    font-weight: 900;
    font-size: 36px;
    line-height: 120%;
    color: var(--clr-primary-700);
    font-family: "Montserrat", sans-serif;
    // margin: 0;
    @media (max-width: 767px) {
      font-size: 24px;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -15px;
      width: 78px;
      height: 6px;
      background-color: var(--clr-primary-400);
    }
  }

  &__text {
      font-size: 16px;
      line-height: 160%;
    }
}

.grecaptcha-badge {
  visibility: hidden;
}

.description-line__label {
  text-align: start;
  @media (min-width: 576px) {
    text-align: end !important;
  }
}