
@import "@scss/settings/functions.scss";

.landing-work {
  padding-top: 90px;
  padding-bottom: 55px;
  @media (max-width: em(767)) {
    padding-top: 50px;
  }

  .landing-container {
    position: relative;
  }

  &__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    @media (max-width: em(767)) {
      flex-direction: column;

    }
  }

  &__tab {
    &:not(:last-child) {
      margin-right: 60px;
      @media (max-width: em(1200)) {
        margin-right: 30px;
      }
      @media (max-width: em(767)) {
        margin-right: 0;
        margin-bottom: 35px;
      }
    }
    position: relative;

    font-size: 16px;
    button {
      font-weight: 700;
      line-height: 125%;
      font-style: normal;
      text-align: center;
      font-family: "Montserrat", sans-serif !important;
      color: var(--clr-primary-400);
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -8px;
      border-radius: 3px;
      width: 100%;
      height: 2px;
      background-color: var(--clr-primary-400);
      opacity: 0;
      visibility: hidden;
      transition: opacity var(--transition), visibility var(--transition);
    }

    &--active {
      &::before {
        opacity: 1;
        visibility: visible;
        transition: opacity var(--transition), visibility var(--transition);
      }
    }
  }

  &__contents {
    list-style: none;
    margin: 0;
  }
  &__content {
    display: none;

    &-title {
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 24px;
      line-height: 125%;
      color: var(--clr-primary-700);
      z-index: 1000;
      @media (max-width: em(767)) {
        margin-bottom: 25px;
      }
    }

    &--active {
      display: block;
    }

    &-wrapper {
      margin-right: auto;
      margin-left: auto;
      max-width: 960px;
    }

    &-inner {
      &-enter {
        opacity: 0;
        visibility: hidden;
      }
      &-enter-active {
        opacity: 1;
        visibility: visible;
      }
      &-exit {
        opacity: 1;
        visibility: visible;
      }
      &-exit-active {
        opacity: 0;
        visibility: hidden;
      }

      &-enter-active,
      &-exit-active {
        transition: opacity var(--transition), visibility var(--transition);
      }
    }

    &-list {
      counter-reset: count;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    
      margin-bottom: 50px;



      @media (max-width: em(767)) {
        flex-direction: column;

        margin-bottom: 25px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 60px;
        width: 60px;
        height: 60px;
        background-image: url("../../../../../assets/img/ui/arrow.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateX(-50%);

        @media (max-width: em(767)) {
          display: none;
        }
      }
    }

    &-item {
      &:not(:last-child) {
        margin-right: 170px;
        @media (max-width: em(992)) {
          margin-right: 100px;
        }
        @media (max-width: em(767)) {
          margin-right: 0;
          margin-bottom: 25px;
        }
      }
      position: relative;
      padding-top: 50px;
      flex: 1 1 50%;
      align-self: start;
      &::after {
        counter-increment: count;
        content: "0" counter(count);
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 101px;
        line-height: 100px;
        color: #eaefec;
      }
    }
  }

  &__modal {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;

      @media (max-width: em(870)) {
        flex-direction: column-reverse;
      }
    }

    &-close {
      position: absolute;
      right: 15px;
      top: -15px;
      display: block;
      border-radius: 3px;
      width: 50px;
      height: 50px;
      background-color: var(--clr-primary-400);

      @media (max-width: em(992)) {
        position: relative;
        right: initial;
        top: initial;
        margin-right: auto;
        margin-left: auto;
        width: 40px;
        height: 40px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 34px;
        height: 34px;
        background-image: url("data:image/svg+xml,%3Csvg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16.9706' cy='17' r='11.25' transform='rotate(-45 16.9706 17)' stroke='white' stroke-width='1.5'/%3E%3Crect x='12.728' y='19.8284' width='10' height='1.5' transform='rotate(-45 12.728 19.8284)' fill='white'/%3E%3Crect x='13.7886' y='12.7573' width='10' height='1.5' transform='rotate(45 13.7886 12.7573)' fill='white'/%3E%3C/svg%3E");
        transform: translate(-50%, -50%);
      }
    }

    &-img {
      margin-right: 30px;
      width: 100%;
      max-width: 542px;
      height: auto;

      @media (max-width: em(1200)) {
        margin-right: 15px;
      }

      @media (max-width: em(870)) {
        margin-top: 20px;
        margin-right: initial;
      }

      @media (max-width: em(767)) {
        display: none;
      }
    }

    &-info {
      width: 100%;
      max-width: 540px;

      @media (max-width: em(870)) {
        max-width: initial;
      }
    }

    &-title {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 24px;
      line-height: 125%;
      color: var(--clr-primary-700);
      @media (max-width: em(767)) {
        font-size: 18px;
      }
    }

    &-text {
      font-size: 14px;
      line-height: 160%;

      p + p {
        margin-top: 15px;
      }
    }
  }

  .button {
    margin-right: auto;
    margin-left: auto;
  }
}
