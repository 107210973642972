@import "@scss/settings/functions.scss";

.landing-checkbox {
  &__input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
  }

  &__option {
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 14px;
    line-height: 130%;
    color: var(--clr-primary-700);
    @media (max-width: em(767)) {
      font-size: 12px;
    }
  }

  &__box {
    position: relative;
    overflow: hidden;
    margin-right: 15px;
    outline: 1px solid #e0e0e0;
    border-radius: 2px;
    height: 20px;
    min-width: 20px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: var(--clr-default-100);

    @media (max-width: em(767)) {
      margin-right: 7.5px;
      height: 15px;
      min-width: 15px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      display: none;
      width: 12px;
      height: 6px;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      // background-image: url("../../../assets/img/ui/mark.svg");
      // transform: translate(-50%, -50%);
      transform: translate(-50%, -75%) rotate(-45deg);
    }
  }

  &__label {
    font-size: 14px;
    line-height: 130%;
    & a {
      color: var(--clr-primary-400);
      font-weight: 500;
      &:hover,
      &:visited {
        color: var(--clr-primary-400);
      }
    }
    &-required {
      color: #f46767;
      margin-left: 5px;
    }
  }

  &__input:checked + &__box {
    outline: initial;

    &::before {
      display: block;
    }

    background-color: var(--clr-primary-400);
  }
}
