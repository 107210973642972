
@import "@scss/settings/functions.scss";

.landing-seo {
  padding-top: 90px;
  padding-bottom: 90px;

  @media (max-width: em(767)) {
    padding-bottom: 45px;
  }

  &__title {
    margin-bottom: 25px;
    font-weight: 900;
    font-size: 36px;
    line-height: 120%;
    color: var(--clr-primary-700);
    @media (max-width: em(767)) {
      margin-bottom: 15px;
      font-size: 24px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 25px;
  }

  // div + h2,
  // div + h3,
  // div + h4,
  // div + h5,
  // div + h6 {
  //   margin-top: 25px;
  // }

  ul,
  ol {
    list-style: auto;
    list-style-position: inside;
  }

  // h2,
  // h3,
  // h4,
  // h5,
  // h6 {
  //   margin-bottom: 20px;
  //   font-weight: 900;
  //   color: var(--clr-primary-700);
  // }

  h2 {
    font-size: 36px;
    @media (max-width: em(767)) {
      font-size: 24px;
    }
  }

  // h3 {
  //   font-size: 32px;
  //   @media (max-width: em(767)) {
  //     font-size: 22px;
  //   }
  // }

  // h4 {
  //   font-size: 28px;
  //   @media (max-width: em(767)) {
  //     font-size: 20px;
  //   }
  // }

  // h5 {
  //   font-size: 24px;
  //   @media (max-width: em(767)) {
  //     font-size: 18px;
  //   }
  // }

  // h6 {
  //   font-size: 20px;
  //   @media (max-width: em(767)) {
  //     font-size: 16px;
  //   }
  // }
}
