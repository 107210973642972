.static-blur {
    position: absolute;
    top: 0;
    left: 3.8rem;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // backdrop-filter: blur(7px);
    // -webkit-backdrop-filter: blur(7px);
    &__button {
        position: absolute !important;
        top: 50% !important;
        left: calc(50vw - 5rem) !important;
        transform: translate(-50%, -50%) !important;
    }
}

.react-dataTable {
    .rdt_TableHead {
        .rdt_TableHeadRow {
            & [data-column-id="blur"] {
                padding: 0 !important;
            }
        }
    }
}
