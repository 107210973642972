.swiper {
  position: relative;
  z-index: 1;
  list-style: none;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  /* Fix of Webkit flickering */
}
.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  transition-property: transform;
}
.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.swiper-pointer-events {
  touch-action: pan-y;
  &.swiper-vertical {
    touch-action: pan-x;
  }
}
.swiper-slide {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  transition-property: transform;
}
.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-pagination {
  position: absolute;
  z-index: 10;
  text-align: center;
  transform: translate3d(0, 0, 0);
  transition: 300ms opacity;

  @media (max-width: em(767)) {
    position: initial;
    margin-top: 40px;
  }

  @media (max-width: em(576)) {
    margin-top: 20px;
  }
  &.swiper-pagination-hidden {
    opacity: 0;
  }
  .swiper-pagination-disabled > &,
  &.swiper-pagination-disabled {
    display: none !important;
  }
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  left: 0;
  bottom: 0px;
  width: 100%;
}

.swiper-pagination-bullet {
  display: inline-block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #dadada;
  .swiper-pagination-clickable & {
    cursor: pointer;
  }

  &:only-child {
    display: none !important;
  }
}
.swiper-pagination-bullet-active {
  background-color: var(--clr-primary-400);
}

.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets {
  left: 20px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
  .swiper-pagination-bullet {
    display: block;
    & + .swiper-pagination-bullet {
      margin-top: 25px;
    }
  }
  &.swiper-pagination-bullets-dynamic {
    top: 50%;
    width: 8px;
    transform: translateY(-50%);
    .swiper-pagination-bullet {
      display: inline-block;
      transition:
        200ms transform,
        200ms top;
    }
  }
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-horizontal.swiper-pagination-bullets {
  .swiper-pagination-bullet {
    & + .swiper-pagination-bullet {
      margin-left: 25px;
    }
  }
  &.swiper-pagination-bullets-dynamic {
    left: 50%;
    white-space: nowrap;
    transform: translateX(-50%);
    .swiper-pagination-bullet {
      transition:
        200ms transform,
        200ms left;
    }
  }
}
.swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition:
    200ms transform,
    200ms right;
}
.swiper-pagination-lock {
  display: none;
}

.swiper-button-prev {
  display: none;
}

.swiper-button-next {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 10;
  display: none;
  width: 50px;
  height: 50px;
  background-image: url("../../assets/img/ui/slider-arrow.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%);
  cursor: pointer;

  @media (max-width: em(576)) {
    display: block;
  }

  @media (max-width: em(420)) {
    width: 40px;
    height: 40px;
  }

  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  &.swiper-button-hidden {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
  }
  .swiper-navigation-disabled & {
    display: none !important;
  }
  &:after {
    font-family: swiper-icons;
    font-variant: initial;
    font-size: var(--swiper-navigation-size);
    line-height: 1;
    letter-spacing: 0;
    text-transform: none !important;
  }
}

.swiper-button-lock {
  display: none;
}
