/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.flex-none {
  flex: none !important;
}

.z-index-1 {
  z-index: 1;
}
.z-index-10 {
  z-index: 10;
}

.z-index-100 {
  z-index: 100;
}

.z-index-1000 {
  z-index: 1000;
}

.actions {
  &-none:hover,
  &-none:focus {
    background-color: transparent !important;
  }
}

.app-content {
  input {
    appearance: none;
    -webkit-appearance: none;
    box-shadow: none;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset; /* можно изменить на любой вариант цвета */
    -webkit-text-fill-color: #797985 !important;
    transition: none !important;
  }

  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px white inset !important; /* можно изменить на любой вариант цвета */
    transition: none !important;
    // border: 2px solid var(--sub-color) !important;
  }

  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none !important;
  }

  input[type="password"]::-webkit-contacts-auto-fill-button,
  input[type="password"]::-webkit-credentials-auto-fill-button {
    visibility: hidden !important;
    display: none !important;
    pointer-events: none !important;
    height: 0 !important;
    width: 0 !important;
    margin: 0 !important;
  }
}
.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.nav-tabs .nav-link {
  overflow: visible;
}

.nav-tabs .nav-link:after {
  background: transparent !important;
}

.nav-tabs .nav-link.active:after {
  background: linear-gradient(30deg, #7367f0, rgba(115, 103, 240, 0.5)) !important;
}

.tabs {
  &__row {
    &-btns {
      @media (max-width: 1100px) {
        display: none !important;
      }
    }

    &-btn--mobile {
      display: none;

      @media (max-width: 1100px) {
        display: block;
      }
    }
  }
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  height: inherit !important;
}

.react-dataTable__pagination {
  position: static !important;
  .rdt_Table {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    &:has(.no-data-body) {
      border-bottom: none !important;
    }
  }
}

.pagination-s {
  .row-select {
    &__container {
      &:hover,
      &:focus {
        .select__indicator {
          background-color: transparent;
          transform: rotate(180deg) !important;
          transition: transform 0.3s ease-in-out !important;
        }
      }
    }
    .select {
      &__control {
        min-height: unset !important;
        // border: 0;

        box-shadow: none;
        // padding: 0;
        background-color: transparent;
        cursor: pointer;
        &:hover,
        &:focus {
          // border: 0;
          border-color: #7367f0 !important;
          box-shadow: none;
        }
      }
      &__value-container {
        // padding: 0;
        padding: 5px;
      }
      &__indicator {
        // padding: 0;
        padding: 5px;
        transition: tranform 0.3s ease-in-out;
      }
      &__menu {
        // border: 1px solid;
        // border-color: currentColor !important;
        margin: 0;
      }
      &__menu-list {
        padding: 0;
      }
      &__option {
        padding: 2px 5px;
      }
    }
  }
  &-item {
    background-color: #f3f2f7;
  }

  &-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    height: 32px;
    min-width: 32px;
    margin-left: -1px;
    line-height: 1.25;
  }

  &-active {
    & .pagination-s-link {
      border-radius: 5em;
      background-color: #7367f0;
      color: #fff;
      font-weight: 600;

      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }

  & li:first-child {
    border-radius: 5em;
  }

  & li:nth-child(2) {
    border-top-left-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important;
  }

  & li:nth-last-child(2) {
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important;
  }
}
