@import "@scss/settings/functions.scss";

.landing-crm {
  padding-top: 60px;

  // font-family: sans-serif;
  &__title {
    margin-bottom: 45px;

    @media (max-width: em(767)) {
      margin-bottom: 35px;
    }
  }

  &__list {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 45px;

    @media (max-width: em(1200)) {
      grid-gap: 15px;
    }
  }

  &__slider {
    @media (max-width: em(991)) {
      display: block !important;
    }

    // padding-right: 15px;
    // padding-left: 15px;

    .swiper {
      padding: 0 15px;
      // overflow: initial;
      // padding-left: calc((100% - 1170px) / 2);
      &-wrapper {
        margin-bottom: 45px;
      }
      .swiper-button-next {
        background-image: url("../../../../../assets/img/ui/crm-arrow.svg");
      }
    }
  }

  &__item {
    position: relative;
    border-radius: 5px;
    padding: 110px 30px 30px;
    min-height: 340px;
    box-shadow: 0px 10px 30px rgba(36, 36, 40, 0.1);
    transition: opacity 0.6s;

    @media (max-width: em(1200)) {
      padding-right: 15px;
      padding-left: 15px;
    }

    @media (max-width: em(576)) {
      opacity: 0.5;
      transition: opacity 0.6s;
      pointer-events: none;
    }

    &.swiper-slide-active {
      @media (max-width: em(576)) {
        opacity: 1;
        transition: opacity 0.6s;
        pointer-events: initial;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 181px;
      height: 264px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &:nth-child(odd) {
      background-image: linear-gradient(229.55deg, #f6fcf8 1.25%, #edf3f0 100%);

      &::before {
        background-image: url("../../../../../assets/img/crm/light.svg");
      }

      .landing-crm__item-title {
        color: var(--clr-primary-700);
      }
    }

    &:nth-child(even) {
      background-image: linear-gradient(229.55deg, #51ce7c 1.25%, #32955c 100%);

      &::before {
        background-image: url("../../../../../assets/img/crm/dark.svg");
      }

      .landing-crm__item-title,
      .landing-crm__item-text {
        color: var(--clr-default-100);
      }
    }

    &-title,
    &-text {
      position: relative;
      z-index: 2;
    }

    &-title {
      margin-bottom: 15px;
      font-weight: 900;
      font-size: 24px;
      line-height: 120%;
    }

    &-text {
      font-size: 16px;
      line-height: 160%;
    }
  }
}
