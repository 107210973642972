

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 2px solid var(--clr-primary-400);
  border-radius: 3px;
  padding-right: 25px;
  padding-left: 25px;
  min-width: 210px;
  min-height: 50px;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-400);
  transition: color var(--transition), background-color var(--transition);

  @media (hover) {
    &:hover {
      color: var(--clr-primary-400);
      background-color: transparent;
      transition: color var(--transition), background-color var(--transition);

      .circle {
        background-color: var(--clr-primary-400);
      }
    }
  }
}




@keyframes blink {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }
  40% {
    opacity: 1;
    transform: scale(1,1);
  }
  60% {
      opacity: 1;
      transform: scale(1,1);
    }
  100% {
      opacity: 0;
      transform: scale(0, 0);
  }
}

.circle {
  margin: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--clr-default-100);
  animation: blink 1s infinite linear;
}

.circle:nth-child(2) {
  animation-delay: 0.1s;
}

.circle:nth-child(3) {
  animation-delay: 0.2s;
}