:root {
  // colors
  --clr-default-100: #fff;
  --clr-default-400: #181818;
  --clr-default-900: #000;

  --clr-primary-400: #35be70;
  --clr-primary-700: #3a4763;
  --clr-text: #797985;

  // Transition speed
  --transition: 0.3s;
}
