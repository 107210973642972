@import "@scss/settings/functions.scss";

.landing-footer {
  position: relative;
  padding-top: 80px;
  padding-bottom: 60px;
  background-color: #f8f8f8;
  // font-family: sans-serif;
  &__modal-text {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    overflow-x: hidden;
    & td {
      padding: 5px;
      border: 1px solid #ccc;
      & > * {
        margin: 0;
      }
    }
    & a {
      color: var(--clr-primary-400) !important;
      // font-weight: 600;
    }
    // & p + p {
    //   &:not(:last-child) {
    //     margin-bottom: 0;
    //   }
    // }
    & ul {
      list-style: disc !important;
      margin-left: 20px;
    }
    & ol {
      list-style: decimal !important;
      margin-left: 20px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 120px;
    width: 100%;
    height: 1px;
    background-color: #ced1d8;
    @media (max-width: em(576)) {
      bottom: 200px;
    }
  }

  &__inner {
    @media (max-width: em(767)) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-top: 20px;
    }

    @media (max-width: em(576)) {
      display: block;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    @media (max-width: em(767)) {
      flex-direction: column;
    }

    &-left {
      display: flex;
      flex-direction: column;

      & .landing-logo {
        margin-bottom: 50px;
        @media (max-width: em(767)) {
          margin-bottom: 25px;
        }
      }
    }

    &-center {
      display: flex;
      flex-direction: column;
      margin-right: 15px;
      margin-left: 15px;

      & .landing-footer__title {
        margin-bottom: 13px;
      }

      @media (max-width: em(767)) {
        margin-top: 20px;
        margin-right: initial;
        margin-bottom: 20px;
        margin-left: initial;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      & .landing-footer__title {
        margin-bottom: 31px;
        @media (max-width: em(767)) {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__bottom {
    // position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: em(576)) {
      flex-direction: column;
    }

    &-copyright {
      font-size: 14px;
      @media (max-width: em(576)) {
        margin-bottom: 20px;
      }
    }

    &-list {
      display: flex;

      align-items: center;
      margin: 0;
      @media (max-width: em(576)) {
        flex-direction: column;
      }
    }
    &-item {
      &:not(:last-child) {
        margin-right: 20px;
        @media (max-width: em(576)) {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
    }
    &-button {
      font-family: "Montserrat", sans-serif !important;
      font-size: 14px;
      color: var(--clr-primary-700);
    }
  }

  &__bottom-button,
  &__nav-link {
    position: relative;
    display: inline-block;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -2px;
      width: 0;
      height: 2px !important;
      background-color: var(--clr-primary-700);
      transform: translateX(-50%);
      transition: width var(--transition);
    }

    @media (hover) {
      &:hover,
      &:focus {
        color: var(--clr-primary-700);
        &::before {
          width: 100%;
          transition: width var(--transition);
        }
      }
    }
    &:active {
      color: var(--clr-primary-700);
    }
  }

  &__payment {
    &-list {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: repeat(3, 1fr);
      margin: 0;
      @media (max-width: em(992)) {
        grid-gap: 10px;
      }

      @media (max-width: em(767)) {
        grid-template-columns: repeat(6, 1fr);
      }

      @media (max-width: em(576)) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &-item {
      max-width: 93px;
      @media (max-width: em(576)) {
        justify-self: center;
      }
    }
  }

  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    color: var(--clr-primary-700);
  }

  &__nav {
    &-list {
      margin: 0;
      display: flex;
      flex-direction: column;

      @media (max-width: em(767)) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
      }
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: 13px;
        @media (max-width: em(767)) {
          margin-bottom: 0;
          margin-right: 13px;
        }
      }
    }

    &-link {
      line-height: 160%;
      font-size: 16px;
      color: var(--clr-primary-700);
    }
  }

  &__social {
    &-list {
      // margin-bottom: 35px;
      display: flex;
      margin: 0;

      // @media (max-width: em(767)) {
      //   margin-top: initial;
      //   margin-bottom: initial;
      // }

      @media (max-width: em(576)) {
        flex-wrap: wrap;
      }
    }

    &-item {
      margin-bottom: 35px;
      &:not(:last-child) {
        margin-right: 20px;
      }

      @media (max-width: em(767)) {
        margin-bottom: initial;
      }

      @media (max-width: em(576)) {
        margin-bottom: 20px;
        flex-wrap: wrap;
      }
    }

    &-link {
      max-width: 40px;

      rect {
        fill: #d7f2e2;
        transition: fill var(--transition);
      }

      path {
        fill: #35be70;
        transition: fill var(--transition);
      }

      @media (hover) {
        &:hover,
        &:focus {
          rect {
            fill: #35be70;
            transition: fill var(--transition);
          }

          path {
            fill: #d7f2e2;
            transition: fill var(--transition);
          }
        }
      }
    }
  }

  & .landing-lang {
    width: 250px;
    margin: 0;
    &__current {
      &::after {
        margin-left: auto;
      }
    }
    &__list {
      @media (max-width: em(992)) {
        bottom: auto;
      }
    }
  }
}
